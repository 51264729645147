<template>
  <div class="main-page">
    <div class="screen-shot-wrapper">
      <rnd-btn @click="mainTableScreenshot($refs.screenShotArea)" style="top: 0" :class="[{}, 'absolute']">
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none"
             xmlns="http://www.w3.org/2000/svg">
          <path d="M18.2222 4.5H5.77778C4.79594 4.5 4 5.29594 4 6.27778V18.7222C4 19.7041 4.79594 20.5 5.77778 20.5H18.2222C19.2041 20.5 20 19.7041 20 18.7222V6.27778C20 5.29594 19.2041 4.5 18.2222 4.5Z"
                stroke="currentColor" stroke-width="1.77778" stroke-linecap="round"
                stroke-linejoin="round"/>
          <path d="M20.0001 15.1666L15.5556 10.7222L5.77783 20.4999" stroke="currentColor"
                stroke-width="1.77778" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M8.889 10.7223C9.62538 10.7223 10.2223 10.1254 10.2223 9.389C10.2223 8.65262 9.62538 8.05566 8.889 8.05566C8.15262 8.05566 7.55566 8.65262 7.55566 9.389C7.55566 10.1254 8.15262 10.7223 8.889 10.7223Z"
                stroke="currentColor" stroke-width="1.77778" stroke-linecap="round"
                stroke-linejoin="round"/>
        </svg>
      </rnd-btn>
      <section class="section-dashboard">
        <table v-if="dashboard.length > 0" cellspacing="0" ref="screenShotArea">
          <thead>
          <tr>
            <th
                v-for="title in headers"
                :key="title.key" :class="title.style"
            >
              {{ title.title }}
            </th>
          </tr>
          </thead>
          <tbody>
          <tr
              v-for="(row, index) in dashboard" :key="`${row.symbol}${index}`"
          >
            <td v-for="cell in headers"
                :key="`${row[cell.key]}${index}${cell.key}`"
                :class="cell.displayStyle(row, cell.key)"
            >
              {{ cell.displayMethod(row[cell.key]) }}
            </td>
          </tr>
          </tbody>
        </table>
      </section>
    </div>
    <section class="section-right" >
      <div class="screen-shot-wrapper">
        <div class="screen-shot-area" ref="screenShotWidgets">
          <div v-for="(symbol, index) in syList" :key="symbol.symbol"
           :class="['section-right-item', {'not-change': !symbol.isChange}]" @click="menu(symbol.symbol, index)">
        <iframe
            :key="symbol.symbol"
            scrolling="no"
            allowtransparency="true"
            frameborder="0"
            :src="`https://s.tradingview.com/embed-widget/single-quote/?locale=ru#${frameList[index]}`"
        ></iframe>
        <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M4.99885 5.47533L9.00552 1.46866L8.06352 0.52533L4.99885 3.592L1.93485 0.52533L0.992188 1.468L4.99885 5.47533Z"
              fill="#5B5C67"/>
        </svg>
      </div>
        </div>
      </div>
      <div class="screen-shot-wrapper moon">
        <rnd-btn @click="onScreenShot($refs.screenShotMoon)" :class="[{}, 'absolute']">
          <svg width="24" height="25" viewBox="0 0 24 25" fill="none"
               xmlns="http://www.w3.org/2000/svg">
            <path d="M18.2222 4.5H5.77778C4.79594 4.5 4 5.29594 4 6.27778V18.7222C4 19.7041 4.79594 20.5 5.77778 20.5H18.2222C19.2041 20.5 20 19.7041 20 18.7222V6.27778C20 5.29594 19.2041 4.5 18.2222 4.5Z"
                  stroke="currentColor" stroke-width="1.77778" stroke-linecap="round"
                  stroke-linejoin="round"/>
            <path d="M20.0001 15.1666L15.5556 10.7222L5.77783 20.4999" stroke="currentColor"
                  stroke-width="1.77778" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M8.889 10.7223C9.62538 10.7223 10.2223 10.1254 10.2223 9.389C10.2223 8.65262 9.62538 8.05566 8.889 8.05566C8.15262 8.05566 7.55566 8.65262 7.55566 9.389C7.55566 10.1254 8.15262 10.7223 8.889 10.7223Z"
                  stroke="currentColor" stroke-width="1.77778" stroke-linecap="round"
                  stroke-linejoin="round"/>
          </svg>
        </rnd-btn>
        <div class="screen-shot-area" ref="screenShotMoon">
          <div class="section-right-table">
            <table v-if="moonPhase.length > 0" cellspacing="0">
              <thead>
              <tr>
                <th class="darkblue-bg white">Дата</th>
                <th class="grey-bg white">Moon Sec</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(moon, index) in moonPhase" :key="`${moon}${index}`">
                <td>{{ date(moon.stamp) }}</td>
                <td :class="[`${moon.color}-bg`, 'white']"><b>{{ time(moon.stamp) }}</b></td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="section-right-table">
            <table v-if="smaSmiio.data?.length > 0" cellspacing="0">
            <thead>
            <tr>
              <th v-for="header in smaSmiio.headers" :key="header.id" :class="[header.style, 'white']">{{ header.title }}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(row_, index) in smaSmiio.data" :key="`${row_}${index}`">
              <td
                  v-for="key in smaSmiio.headers"
                  :key="`${row_}${key.id}`"
                  :class="[{red: key==='smiio' && row_[key.id] < 0}]"
              >
                {{ row_[key.id] }}
              </td>
            </tr>
            </tbody>
          </table>
          </div>
          <div class="section-right-table market">
            <table v-if="market.data?.length > 0" cellspacing="0">
            <thead>
            <tr>
              <th colspan="3" class="blue-bg">КАЛЕНДАРЬ СОБЫТИЙ</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(row_, index) in market.data" :key="`row-${index}`">
              <td
                  v-for="(header, cell) in market.headers"
                  :key="`row-${index}${header.key}`"
                  :class="[{'darkblue-bg': index % 2 > 0 & cell===0}, {'water-bg': index % 2 === 0 & cell===0}]"
              >
                <a target="_blank" v-if="header.key==='title'" :href="row_.url" class="link">{{row_[header.key]}}</a>
                <template v-else>
                  {{ marketData(row_, header.key) }}
                </template>
              </td>
            </tr>
            </tbody>
          </table>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import dateFormatter from "../../formatters/dateFormatter";
import domtoimage from "dom-to-image-more";
import RndBtn from "@/components/Blocks/RndBtn/RndBtn";

export default {
  name: "MainPage",
  components: {RndBtn},
  props: {
    dashboard: {
      type: Array,
      default: () => []
    },
    moonPhase: {
      type: Array,
      default: () => []
    },
    smaSmiio: {
      type: Object,
      default: () => {}
    },
    syList: {
      type: Array,
      default: () => []
    },
    market: {
      type: Object,
      default: () => {}
    },
  },
  data() {
    return {
      headers: [
        {
          title: 'Монета', key: 'symbol', style: 'yellow-bg white',
          displayMethod: (data) => data.replace('USDT', ''),
          displayStyle: this.displayStyle,
        },
        {
          title: 'Цена закр.', key: 'price', style: 'darkblue-bg white',
          displayMethod: (data) => `$ ${data.toLocaleString("ru-RU", {
              useGrouping: true,
            })}`,
          displayStyle: this.displayStyle,
        },
        {
          title: 'Изм. 24Ч', key: 'change_price_24h', style: 'darkblue-bg white',
          displayMethod: (data) => `${data.toLocaleString("ru-RU", {
            useGrouping: true,
            minimumFractionDigits: 1,
            maximumFractionDigits: 2
          })}%`,
          displayStyle: this.displayStyle,
        },
        {
          title: '1Ч-2Д', key: 'zone_1h', style: 'darkblue-bg white',
          displayMethod: (data) => data,
          displayStyle: this.displayStyle,
        },
        {
          title: '2Ч-3Д', key: 'zone_2h', style: 'darkblue-bg white',
          displayMethod: (data) => data,
          displayStyle: this.displayStyle,
        },
        {
          title: '3Ч-4Д', key: 'zone_3h', style: 'darkblue-bg white',
          displayMethod: (data) => data,
          displayStyle: this.displayStyle,
        },
        {
          title: '4Ч-1Н', key: 'zone_4h', style: 'darkblue-bg white',
          displayMethod: (data) => data,
          displayStyle: this.displayStyle,
        },
        {
          title: 'FF+', key: 'f_summ_pos_1h', style: 'green-bg white',
          displayMethod: (data) => data,
          displayStyle: this.displayStyle,
        },
        {
          title: 'FF+', key: 'f_summ_pos_2h', style: 'green-bg white',
          displayMethod: (data) => data,
          displayStyle: this.displayStyle,
        },
        {
          title: 'FF+', key: 'f_summ_pos_3h', style: 'green-bg white',
          displayMethod: (data) => data,
          displayStyle: this.displayStyle,
        },
        {
          title: 'FF+', key: 'f_summ_pos_4h', style: 'green-bg white',
          displayMethod: (data) => data,
          displayStyle: this.displayStyle,
        },
        {
          title: 'FF-', key: 'f_summ_neg_1h', style: 'red-bg white',
          displayMethod: (data) => data,
          displayStyle: this.displayStyle,
        },
        {
          title: 'FF-', key: 'f_summ_neg_2h', style: 'red-bg white',
          displayMethod: (data) => data,
          displayStyle: this.displayStyle,
        },
        {
          title: 'FF-', key: 'f_summ_neg_3h', style: 'red-bg white',
          displayMethod: (data) => data,
          displayStyle: this.displayStyle,
        },
        {
          title: 'FF-', key: 'f_summ_neg_4h', style: 'red-bg white',
          displayMethod: (data) => data,
          displayStyle: this.displayStyle,
        },
      ],
      defaultSymbol: {
        "symbol": "FX:EURUSD",
        "width": 350,
        "colorTheme": "dark",
        "isTransparent": true,
        "locale": "ru",
      },
    }
  },
  computed: {
    frameList() {
      return this.syList.map(el => {
        const _symbol = {...this.defaultSymbol}
        _symbol.symbol = el.symbol
        return JSON.stringify(_symbol)
      })
    }
  },
  methods: {
    displayStyle(row, key) {
      const styles = []
      if (row.symbol.toLowerCase().includes('eth')) {
        styles.push('separator')
      }
        switch (key) {
          case 'symbol':
            ['eth', 'btc'].includes(row[key].toString().replace('USDT', '').toLowerCase()) ? styles.push('yellow') : styles.push('blue');
            break;
          case 'f_summ_pos_1h':
          case 'f_summ_neg_1h':
            if (row[key]!==null & row[key]!==undefined) {
              if (row[key] < 0) styles.push('red');
              if (row[key] > 0) styles.push('green');
            }
            styles.push('sector');
            styles.push('text-center')
            break;
          case 'f_summ_pos_2h':
          case 'f_summ_pos_3h':
          case 'f_summ_pos_4h':
          case 'f_summ_neg_2h':
          case 'f_summ_neg_3h':
          case 'f_summ_neg_4h':
            styles.push('text-center')
            if (row[key]!==null & row[key]!==undefined) {
              if (row[key] < 0) styles.push('red');
              if (row[key] > 0) styles.push('green');
            }
            break;
          case 'change_price_24h':
            if (row[key]!==null & row[key]!==undefined) {
              if (row[key] < 0) styles.push('red');
              if (row[key] > 0) styles.push('green');
            }
            break;
          case 'zone_1h':
            styles.push('sector');
            if (row[key]!==null & row[key]!==undefined) {
              row[key].toString().includes('LONG') ? styles.push('green') : row[key].toString().includes('SHORT') ? styles.push('red') : '';
            }
            break;
          case 'zone_2h':
          case 'zone_3h':
          case 'zone_4h':
            if (row[key]!==null & row[key]!==undefined) {
              row[key].toString().includes('LONG') ? styles.push('green') : row[key].toString().includes('SHORT') ? styles.push('red') : '';
            }
            break;
          case 'srsi':
            if (row[key]!==null & row[key]!==undefined) {
              row[key].toLowerCase().includes('вниз') ? styles.push('red') : row[key].toLowerCase().includes('вверх') ? styles.push('green') : '';
            }
            break;
        }
      return styles
    },
    date(date) {
      return dateFormatter(date, false, false)
    },
    time(date) {
      return dateFormatter(date, false, false, true)
    },
    marketData(row, key) {
      switch (key) {
        case 'symbol':
          return row[key].join(',')
        default:
          return row[key]
      }
    },
    async mainTableScreenshot(screenShotArea) {
      const backupHeaders = [...this.headers]
      this.headers = this.headers.filter(el => !['f_summ_pos', 'f_summ_neg', 'sf_summ'].includes(el.key))
      await this.onScreenShot(screenShotArea)
      this.headers = backupHeaders
    },
    async onScreenShot(screenShotArea) {
      const height = screenShotArea.offsetHeight;
      const width = screenShotArea.offsetWidth;
      await domtoimage.toJpeg(screenShotArea, {quality: 0.9, height: height, width: width, style: {'overflow': 'hidden'}})
          .then((dataUrl) => {
            this.doingScreenshot = false
            const link = document.createElement('a');
            link.download = 'screenshot.jpeg';
            link.href = dataUrl;
            link.click();
          })
    },
  }
}
</script>
<style scoped lang="scss">
.screen-shot-wrapper {
  //flex: 1 1 auto; // растягивает таблицу
  position: relative;
  display: flex;
}
.screen-shot-area {
  flex: 1 1 auto;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.absolute {
  position: absolute;
  top: -50px;
  right: 0;
  z-index: 150;
  color: white;
  border-radius: 50%;
  background-color: #4b4343;
  transition: all .4s;
}

td {
  &.sector {
    border-left-color: #72727a;
  }
}
.link {
  transition: all .2s;
  text-decoration: none;
  &:link {
    color: $blue-middle;
  }
  &:visited {
    color: $blue-middle;
  }
  &:focus {}
  &:hover {
    color: #48c4ff;
  }
  &:active {}
}
.market {
  margin-top: 30px;
}
</style>
