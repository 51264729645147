<template>
<!--    <main-page_old-->
<!--            :bm-data="MARKET"-->
<!--            :sy-list="SY_LIST"-->
<!--            :additional="ADDITIONAL_LINES"-->
<!--            :account-ration="ACCOUNT_RATION"-->
<!--            :timeframes="GENERAL_TIMEFRAMES"-->
<!--            @change-coin="changeCoin"-->
<!--    />-->
    <main-page
            :dashboard="DASBOARD"
            :moon-phase="MOONPHASE"
            :sma-smiio="SMASMIIO"
            :sy-list="SY_LIST"
            :market="COIN_MARKET"
    />
</template>

<script>
    import MainPage from "../components/Pages/MainPage";
    import {mapActions, mapGetters} from "vuex";
    export default {
        name: "Main",
        components: {MainPage},
        computed: {
            // ...mapGetters(["MARKET", 'SY_LIST', 'ADDITIONAL_LINES', 'ACCOUNT_RATION', 'GENERAL_TIMEFRAMES'])
            ...mapGetters(['DASBOARD', 'MOONPHASE', 'SMASMIIO', 'SY_LIST', 'COIN_MARKET'])
        },
        methods: {
            // ...mapActions(["getMARKET", "getADDITIONAL_LINES", "changeCoin"])
            ...mapActions(["getDashboard", "getMoonPhase", "getSmaSmiio", 'getMARKET'])
        },
        async mounted() {
            // await this.getMARKET()
            // await this.getADDITIONAL_LINES()
            await this.getDashboard()
            await this.getMoonPhase()
            await this.getSmaSmiio()
            await this.getMARKET()
        }
    }
</script>
